import {
  Box,
  Button,
  ChakraProvider,
  extendTheme,
  Flex,
  Grid,
  Link,
  Text,
  VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { isDesktop, isMobile, useMobileOrientation } from 'react-device-detect';
import './App.css';
import { Logo } from './Logo';
import { TogetherForTheRun } from './TogetherForTheRun';

const buttonsData = [
  {
    text: 'Windrunners A/R Experience - Mile 17.5',
    destination: 'https://windrunners.nike.com',
  },
  {
    text: 'Tortugas Experience - Mile 20',
    destination: 'https://tortugas.nike.com',
  },
  {
    text: 'Run Too Hard Experience - Mile 20.5',
    destination: 'https://runtoohard.nike.com',
  },
  {
    text: 'GumboFit & 7onSundays – Mile 23.5',
    destination: 'https://gumboand7.nike.com',
  },
  {
    text: 'Launch Finish Line A/R Experience',
    destination: 'https://finishline.nike.com',
  },
];

const customTheme = extendTheme({
  fonts: {
    body: 'HelveticaNeueMedium, sans-serif, sans-serif',
  },
  breakpoints: {
    base: '0em',
    sm: '30em',
    md: '48em',
  },
});

function App() {
  const useIsLiveOverride = window.location.search.includes('1696744800000');
  // Sat Oct 07 2023 23:00:00 GMT-0700 (Mountain Standard Time)
  const isLiveDatetime = 1696744740000;
  const now = useIsLiveOverride ? isLiveDatetime : Date.now();
  const isLive = isLiveDatetime - now <= 0;
  const { isLandscape } = useMobileOrientation();
  const [contentMaxH, setContentMaxH] = useState();

  useEffect(() => {
    const getContentMaxH = () => {
      if (isDesktop) {
        setContentMaxH('90%');
        return;
      }

      if (isMobile) {
        if (isLandscape || isLive) {
          setContentMaxH('100%');
          return;
        }
        setContentMaxH('85%');
        return;
      }

      setContentMaxH('100%');
      return;
    };
    getContentMaxH();
  }, [isLandscape, isLive]);

  return (
    <ChakraProvider theme={customTheme}>
      <Box
        m="auto"
        h="100%"
        textAlign="center"
        fontSize="xl"
        backgroundColor="#0287DC"
      >
        <Grid minH="100vh">
          <Flex
            direction="column"
            justify="space-around"
            alignItems="center"
            maxH={contentMaxH}
          >
            <Logo
              h="40vmin"
              pointerEvents="none"
              boxSize="70px"
              mt={['5%', '5%', '2%']}
              mb={isMobile && isLandscape && '15px'}
              alt="Bank of America Chicago Marathon logo"
            />
            <TogetherForTheRun
              h={['60vmin', '60vmin']}
              maxH="40vh"
              my={[isLive && isMobile && !isLandscape ? '20px' : 0]}
              alt="Together For The Run"
            />
            <Text
              fontSize="20px"
              fontWeight="500"
              fontFamily="HelveticaNeueMedium, sans-serif"
              color="white"
              px="10px"
              maxW={['300px', '100%']}
              pt={['0px', '20px', '20px']}
              pb={isMobile && isLandscape ? '20px' : '0'}
            >
              Chicago Marathon A/R Experience
            </Text>
            <Text
              fontSize="16px"
              fontWeight="500"
              fontFamily="HelveticaNeueMedium, sans-serif"
              color="white"
              maxW={['313px', '470px']}
              px="10px"
              pt={[isLive ? '20px' : '0px', '0px', '0']}
              pb={[
                isLive ? '20px' : '0px',
                '20px',
                isMobile && isLandscape ? '20px' : '0px',
              ]}
            >
              The experiences will go live on Sunday, October 8th. Join us at
              the designated cheer zones or at the Finish Line to support the
              Chi in A/R.
            </Text>
            <Box>
              <Text
                fontSize={['24px', '33px']}
                lineHeight="31.5px"
                fontFamily="FuturaExtraBlackCondensedRegular"
                fontWeight="800"
                color="#CFFF5E"
                pt={isMobile && 4}
              >
                {isLive ? "IT'S LIVE!" : 'LIVE IN'}
              </Text>
              <Text
                fontSize={['24px', '33px']}
                lineHeight="31.5px"
                fontFamily="FuturaExtraBlackCondensedRegular"
                fontWeight="800"
                color="#CFFF5E"
                pb={!isLive && isMobile && isLandscape && 10}
              >
                {' '}
                <Countdown
                  date={useIsLiveOverride ? Date.now() - 1 : isLiveDatetime}
                />
              </Text>
            </Box>
            {isMobile && isLive && (
              <Box padding={4}>
                <VStack>
                  <Text
                    fontSize="16px"
                    fontWeight="500"
                    fontFamily="HelveticaNeueMedium, sans-serif"
                    color="white"
                    maxW="292px"
                    px="10px"
                    py="20px"
                  >
                    Head to your chosen cheer location and then launch the
                    experience.
                  </Text>
                  {buttonsData.map((button, index) => (
                    <Button
                      key={index}
                      backgroundColor="#CFFF5E"
                      color="black"
                      marginBottom={4}
                      padding={6}
                      width="100%"
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="24px"
                      borderRadius="19px"
                      maxW="320px"
                      fontFamily="HelveticaNeueMedium, sans-serif"
                    >
                      <Link href={button.destination} isExternal>
                        {button.text}
                      </Link>
                    </Button>
                  ))}
                </VStack>
              </Box>
            )}
          </Flex>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
